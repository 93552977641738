// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-color-primary: #ff4747;
  --ion-color-primary-rgb: 255, 71, 71;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e03e3e;
  --ion-color-primary-tint: #ff5959;

  --ion-color-secondary: #fae01f;
  --ion-color-secondary-rgb: 250, 224, 31;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dcc51b;
  --ion-color-secondary-tint: #fbe335;

  --ion-color-tertiary: #ff7033;
  --ion-color-tertiary-rgb: 255, 112, 51;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0632d;
  --ion-color-tertiary-tint: #ff7e47;

  --ion-color-success: #30b063;
  --ion-color-success-rgb: 48, 176, 99;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #2a9b57;
  --ion-color-success-tint: #45b873;

  --ion-color-warning: #fac30f;
  --ion-color-warning-rgb: 250, 195, 15;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dcac0d;
  --ion-color-warning-tint: #fbc927;

  --ion-color-danger: #ff8091;
  --ion-color-danger-rgb: 255, 128, 145;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e07180;
  --ion-color-danger-tint: #ff8d9c;

  --ion-color-dark: #4a4d55;
  --ion-color-dark-rgb: 74, 77, 85;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #41444b;
  --ion-color-dark-tint: #5c5f66;

  --ion-color-medium: #929ab9;
  --ion-color-medium-rgb: 146, 154, 185;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #8088a3;
  --ion-color-medium-tint: #9da4c0;

  --ion-color-light: #ededed;
  --ion-color-light-rgb: 237, 237, 237;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d1d1d1;
  --ion-color-light-tint: #efefef;
}

html {
  /* 
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

/**
  * Barcode Scanning
  * -------------------------------------------
  */
body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

.barcode-scanning-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanning-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}